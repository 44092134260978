<template>
  <v-container>
    <v-data-table :items="payments" class="elevation-1"> 
      <template v-slot:[`item.date`]="{ item }">
        <td>{{ formatDate(item.date) }}</td>
      </template>
      <template v-slot:[`item.event_event_name`]="{ item }">
        <td>{{ item.event_event_name }}</td>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <td>{{ item.description }}</td>
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        <td>{{ item.amount }}</td>
      </template>
      <template v-slot:[`item.currency`]="{ item }">
        <td>{{ item.currency }}</td>
      </template>
    </v-data-table>
  </v-container>
</template>


<script setup>
import { ref, onMounted } from 'vue';
import apiClient from '/api.js';

const payments = ref([]);

async function fetchPayments() {
  try {
    const response = await apiClient.get('payment/');
    payments.value = response.data;
  } catch (error) {
    console.error('Error fetching payments:', error);
  }
}

onMounted(fetchPayments);

function formatDate(dateString) {
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };
  return new Date(dateString).toLocaleString('cs-CZ', options);
}

</script>
