<template>
  <v-container>
    <h3>Registrovaní uživatelé <span v-if="!loading">({{ accountsCount }})</span> :</h3>

   <v-row class="mt-1">
      <v-col cols="12" md="2">
        <v-text-field
            v-model="filter_last_name"
            label="Příjmení"
            variant="outlined"            
            hide-details
            density="compact"
       />
      </v-col>
      <v-col cols="12" md="1">
        <v-text-field
            v-model="filter_index"
            label="Index"
            variant="outlined"
            hide-details
            density="compact"
        />
      </v-col>

      <v-col cols="12" md="2">
        <v-text-field
            v-model="filter_club"
            label="Zkratka oddílu"
            variant="outlined"
            hide-details
            density="compact"
        />
      </v-col>
<!--      <v-col cols="12" md="3">-->
<!--        <v-text-field-->
<!--            v-model="filter_gender"-->
<!--            label="Pohlaví"-->
<!--            variant="outlined"-->
<!--            hide-details-->
<!--            single-line-->
<!--        />-->
<!--      </v-col>-->
<!--      <v-col cols="12" md="3">-->
<!--        <v-text-field-->
<!--            v-model="filter_min_year"-->
<!--            label="Ročník od"-->
<!--            variant="outlined"-->
<!--            hide-details-->
<!--            single-line-->
<!--        />-->
<!--      </v-col>-->
<!--      <v-col  cols="12" md="3">-->
<!--        <v-text-field-->
<!--            v-model="filter_max_year"-->
<!--            label="Ročník do"-->
<!--            variant="outlined"-->
<!--            hide-details-->
<!--            single-line-->
<!--        />-->
<!--      </v-col>-->
<!--      <v-col cols="12" md="3">-->
<!--        <v-text-field-->
<!--            v-model="filter_category"-->
<!--            label="Kategorie"-->
<!--            variant="outlined"-->
<!--            hide-details-->
<!--            single-line-->
<!--        />-->
<!--      </v-col>-->
<!--      <v-col cols="12" md="3">-->
<!--        <v-text-field-->
<!--            v-model="filter_referee"-->
<!--            label="Rozhodčí"-->
<!--            variant="outlined"-->
<!--            hide-details-->
<!--            single-line-->
<!--        />-->
<!--      </v-col>-->
<!--      <v-col cols="12" md="3">-->
<!--        <v-text-field-->
<!--            v-model="filter_coach"-->
<!--            label="Trenér"-->
<!--            variant="outlined"-->
<!--            hide-details-->
<!--            single-line-->
<!--        />-->
<!--      </v-col>-->
<!--      <v-col cols="12" md="3">-->
<!--        <v-text-field-->
<!--            v-model="filter_vt"-->
<!--            label="VT"-->
<!--            variant="outlined"-->
<!--            hide-details-->
<!--            single-line-->
<!--        />-->
<!--      </v-col>-->
<v-col>
        <v-btn color="primary" @click="fetchAccounts" :disabled="!valid">Filtrovat</v-btn>
      </v-col>

    </v-row>


    <v-row>
      <v-col>
        <v-data-table
            density="comfortable"
            :headers="accountHeaders"
            :items="accounts"
            item-key="id"
            :items-per-page="500"
            :sort-by="sortBy"
            :loading="loading"
            loading-text="Načítám data..."
        >
        <!-- <template v-slot:loading>
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </template> -->

          <template v-slot:[`item.last_name`]="{ item }">
            <v-tooltip location="top center" origin="auto" :text="'Poslední aktivita: ' + $formatDateTime(item.last_activity)">
              <template v-slot:activator="{ props }">
                <router-link v-bind="props" :to="'/ucet/' + item.id">{{ item.last_name }}</router-link>
              </template>
            </v-tooltip> 
          </template>
          <!-- <template v-slot:[`item.gender`]="{ item }">
            {{ sexOptions.formatGender(item.gender) }}
          </template> -->
          <template v-slot:[`item.groups`]="{ item }">
            {{ formatGroups(item.groups) }}
          </template>
          <template v-slot:[`item.last_activity`]="{ item }">
            {{ $formatDateTime(item.last_activity) }}
          </template>

          <template v-slot:no-data>
            <v-alert type="info" class="ma-4">
              Nenašel jsem žádná data
            </v-alert>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>


<script setup>
import {ref, onMounted} from 'vue';
import apiClient from '/api.js';

const valid = ref(true);
const accounts = ref([]);
const loading = ref(true);  // Přidáme proměnnou loading
const accountsCount = ref(0);

const filter_last_name = ref("")
const filter_index = ref("")
const filter_club = ref("")
// const filter_gender = ref(false)
// const filter_min_year = ref("")
// const filter_max_year = ref("")
// const filter_category= ref("")
// const filter_referee = ref("")
// const filter_coach = ref("")
// const filter_vt = ref("")


const accountHeaders = [
  // { title: 'ID', value: 'id', sortable: true, align: 'start' },
  {title: 'Příjmení', value: 'last_name', sortable: true, align: 'start'},
  {title: 'Jméno', value: 'first_name', sortable: true, align: 'start'},
  {title: 'Index', value: 'index', sortable: true, align: 'start'},
  // {title: 'Pohlaví', value: 'gender', sortable: true, align: 'start'},
  // {title: 'Rok narození', value: 'birth_year', sortable: true, align: 'start'},
  // {title: 'Rozhodčí', value: 'referee', sortable: true, align: 'start'},
  // {title: 'Trenér', value: 'coach', sortable: true, align: 'start'},
  // {title: 'VT', value: 'license', sortable: true, align: 'start'},
  {title: 'Pravomoce', value: 'groups', sortable: false, align: 'start'},
];

const sortBy = [{key: 'last_name', order: 'asc'}]; // Klíč pro řazení


// alternativy práv k hodnotám z api
const groupNames = {
  organizer: "organizátor",
  club_manager: "správce oddílu",
};

// const sexOptions = {
//   options: [{ title: 'Muž', value: 'M' }, { title: 'Žena', value: 'F' }],
//   formatGender(gender) {
//     const option = this.options.find(option => option.value === gender);
//     return option ? option.title : gender;
//   }
// };

// přidání alternativ do groups
const formatGroups = (groups) => {
  return groups.map(group => groupNames[group] || group).join(', ');
};

const fetchAccounts = async () => {
  try {
    const params = {};

    // Přidáme příjmení do parametrů, pokud je vyplněné
    if (filter_last_name.value.trim()) {
      params.last_name = filter_last_name.value.trim();
    }

    // Přidáme zkratku klubu do parametrů, pokud je vyplněná
    if (filter_club.value.trim()) {
      params.club_shortcut = filter_club.value.trim();
    }
    // Přidáme index do parametrů, pokud je vyplněný
    if (filter_index.value.trim()) {
      params.index = filter_index.value.trim();
    }


    // Odešleme GET požadavek s parametry
    const response = await apiClient.get('/members_all/', { params });

    accounts.value = response.data.map(account => ({
      id: account.id,
      first_name: account.first_name,
      last_name: account.last_name,
      index: account.index,
      birth_year: account.birth_year,
      // gender: account.gender,
      last_activity: account.last_activity,
      groups: account.groups,
    }));
    accountsCount.value = accounts.value.length;
  } catch (error) {
    console.error('Error fetching accounts:', error);
  } finally {
    loading.value = false;  // Nastavíme loading na false po načtení dat
  }
};


onMounted(() => {
  fetchAccounts();
});
</script>

<style scoped>


:deep() .v-table .v-table__wrapper > table > thead > tr > th:not(:last-child) {
  border-right: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
}

:deep() .v-table .v-table__wrapper > table > tbody > tr > td:not(:last-child), .v-table .v-table__wrapper > table > tbody > tr > th:not(:last-child) {
  border-right: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
}



/* :deep() table th + th { border-left:10px solid #dddddd; }
:deep() table td + td { border-left:10px solid #dddddd; } */
</style>




