<template>
  <v-container style="max-width: 550px;">
    <!-- Alert pro zobrazení zprávy -->
    <v-alert type="success" v-if="successMessage" @click="clearAlert">{{ successMessage }}</v-alert>
    <v-alert type="error" v-if="errorMessage" @click="clearAlert">{{ errorMessage }}</v-alert>

    <h2>Nastavení uživatele</h2>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12" md="6">
        <v-text-field v-model="user.first_name" label="Jméno" :rules="[rules.required, rules.units]" disabled hide-details="true"></v-text-field>
      </v-col>
        <v-col cols="12" md="6">
        <v-text-field v-model="user.last_name" label="Příjmení" :rules="[rules.required, rules.units]" disabled></v-text-field>
      </v-col>
      </v-row>
      <v-text-field v-model="user.email" label="Email" :rules="[rules.required, rules.email]" required></v-text-field>
      <v-text-field v-model="user.si_number" label="Číslo čipu" type="number" :rules="[rules.siNumber]"></v-text-field>
      <v-text-field v-model="user.new_password" label="Nové heslo" type="password" :rules="[rules.password]" autocomplete="new-password"></v-text-field>
      <v-text-field v-model="confirmPassword" label="Potvrdit nové heslo" type="password" :rules="[() => user.new_password === confirmPassword || 'Hesla se musí shodovat']" v-if="user.new_password" autocomplete="new-password"></v-text-field>
      <v-tooltip location="start center" origin="auto" text="Uživatel, který tě může přihlašovat na soutěže">
        <template v-slot:activator="{ props }">
          <v-autocomplete
            v-bind="props"
            v-model="user.assist_user"
            :items="assistUser"
            label="Správce tvých přihlášek"
            item-value="id"
            item-title="fullName"
            placeholder="Vyberte správce"
            clearable
            no-data-text="Uživatel není v databázi"
        ></v-autocomplete>
        </template>
      </v-tooltip>  
      <v-tooltip location="start center" origin="auto" text="Email není nikdy viditelný pro nepřihlášeného uživatele">
        <template v-slot:activator="{ props }">
          <v-switch v-bind="props" v-model="user.allow_email" color="primary" label="Zobrazit email v informacích o mém účtu"></v-switch>
        </template>
      </v-tooltip> 
      <v-row>
        <v-col cols="12" md="3">
        <v-text-field disabled event_type_display v-model="user.index" label="Index" hide-details="true"></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
        <v-text-field disabled event_type_display v-model="user.birth_year" label="Rok narození" type="number" :rules="[rules.year]" hide-details="true"></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
        <v-select disabled v-model="user.gender" :items="sexOptions" label="Pohlaví" hide-details="true"></v-select>
        </v-col>
        <v-col cols="12" md="3">
        <v-select disabled v-model="user.country" :items="countryOptions" label="Země"></v-select>
        </v-col>
        <!-- <v-text-field v-model="user.adress" label="Adresa"></v-text-field> -->
      </v-row>

      <v-btn color="primary" @click="updateUser" :disabled="!valid">Uložit</v-btn>
    </v-form>
  </v-container>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import apiClient from '/api.js';
import { useAuthStore } from '/auth.js';
import { useRouter } from 'vue-router';

const router = useRouter();
const authStore = useAuthStore();
const successMessage = ref('');
const errorMessage = ref('');
const user = ref({
  email: '',
  first_name: '',
  last_name: '',
  birth_year: null,
  si_number: '',
  index: '',
  gender: '',
  country: '',
  adress: '',
  new_password: '',
  allow_email: false,
});
const confirmPassword = ref('');

const valid = ref(false);
const rules = {
  required: value => !!value || 'Toto pole je povinné.',
  units: value => value.length <= 30 || 'Toto pole nemůže mít více jak 30 znaků',
  email: value => /.+@.+\..+/.test(value) || 'Neplatný email.',
  year: value => !value || (value >= 1900 && value <= new Date().getFullYear()) || 'Neplatný rok.',
  siNumber: value => !value || /^\d{4,8}$/.test(value) || 'Číslo SI musí mít 4 až 8 cifer.',
  password: value => !value || value.length >= 8 || 'Heslo musí mít alespoň 8 znaků.',
};


async function fetchUserSettings() {
  try {
    const response = await apiClient.get('account/settings/');
    user.value = response.data;
  } catch (error) {
    console.error('Nepodařilo se načíst nastavení uživatele:', error);
  }
}

const assistUser = ref([]);

const fetchAssistUser = async () => {
  try {
    const response = await apiClient.get('account/?list=true');
    assistUser.value = response.data; // Naplnění dat do seznamu
  } catch (error) {
    console.error('Chyba při načítání účtů:', error);
  }
};



onMounted(async () => {
// Kontrola, zda existuje zpráva k zobrazení po načtení stránky
  const message = localStorage.getItem('successMessage');
  if (message) {
    successMessage.value = message;
    localStorage.removeItem('successMessage');// Odstranění zprávy po jejím zobrazení
  }

  const errormessage = localStorage.getItem('errorMessage');
  if (errormessage) {
    errorMessage.value = errormessage;
    localStorage.removeItem('errorMessage');// Odstranění zprávy po jejím zobrazení
  }

  await fetchUserSettings();
  fetchAssistUser();
});

 // Sledování změn pro firstName a uložení do localstore
watch(() => user.value.first_name, newVal => {
  localStorage.setItem('firstName', newVal);
});

 // Sledování změn pro last_name a uložení do localstore
watch(() => user.value.last_name, newVal => {
  localStorage.setItem('last_name', newVal);
});


const updateUser = async () => {
  if (valid.value && (!user.value.new_password || user.value.new_password === confirmPassword.value)) {
    try {
      const updateData = { ...user.value, new_password: user.value.new_password ? user.value.new_password : undefined };
      await apiClient.put('account/settings/', updateData);
       // Zde zavoláme logout, pokud byla změna hesla úspěšná
      if (user.value.new_password) {
        authStore.logout(); // Zavolání odhlášení
        localStorage.setItem('successMessage', 'Nastavení bylo uloženo a heslo změněno. Byl jsi odhlášen/a.');
        errorMessage.value = '';
      } else {
        localStorage.setItem('successMessage', 'Nastavení bylo úspěšně aktualizováno.');
        errorMessage.value = '';
        router.go('/nastaveni');
      }

    } catch (error) {
    console.error('Chyba při aktualizaci uživatele:', error);
    if (error.response && error.response.status === 409) {
      errorMessage.value = error.response.data.error; // Zobrazení chyby vrácené z API
      successMessage.value = ''; // Zajištění, že nejsou zobrazeny žádné zprávy o úspěchu
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
      console.error(error);
    } else {
      errorMessage.value = 'Nepodařilo se aktualizovat údaje uživatele.';
      successMessage.value = '';
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrollování nahoru pro zobrazení chybové zprávy
      }
    }
  }
};




</script>

<style scoped>

h2 {
  margin: 10px;
}

</style>