<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h3>API ROBisu</h3>
          <p>API slouží k získání dat z databáze. Využívá se standardizovaných formátů dat. Pro XML formát vycházíme z <a href="https://orienteering.sport/iof/it/data-standard-3-0/" target="_blank">IOF standardu</a>. Pro JSON formát vycházíme z nově vytvořeného <a :href="`${BaseUrl}static/ardf_json_standard.json`" target="_blank">ARDF JSON standardu</a><span style="color:red">. POZOR, je v předběžném testování, může se změnit</span>. Níže jsou možnosti získání dat, které robis nabízí.</p>
        <h4 class="mt-3">GET JSON event</h4>
        <p>Data z celé soutěže (soutěž -> etapy -> přihlášky -> startovky -> výsledky)</p>
        <ul>
          <code>https://rob-is.cz/api/?type=json&name=event&event_id=<span class="blue">robis ID soutěže</span></code>
        </ul>
        


      </v-col>
    </v-row>
    
  </v-container>
</template>

<script setup>

import { ref } from 'vue';


const BaseUrl = ref(process.env.VUE_APP_API_STATIC_URL);





</script>

<style scoped>
ul {
  margin-left:40px;
}

.blue {
  color:blue;
}
</style>
